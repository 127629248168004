/**
 * @flow
 */

import React from "react"
import styled, { keyframes } from "styled-components"

type Props = {
  label: string,
  center?: boolean,
  loading?: boolean,
  primary?: boolean,
  disabled?: boolean,
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Container = styled.div`
  text-align: ${props => (props.center ? `center` : null)};
`

const ButtonWrapper = styled.button`
  position: relative;
  font-family: "AvenirLTStdBlack";
  color: rgba(255, 255, 255, 0.7);
  background-color: transparent;
  border-radius: 0;
  border: 0;
  z-index: 1;
  overflow: hidden;
  padding: 0.25rem calc(0.75rem + 25px) 0.25rem 0.75rem;
  font-size: 1rem;
  &:before {
    content: "";
    top: 0;
    left: 0;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }
`

const Label = styled.div`
  text-transform: uppercase;
`

const Icon = styled.div`
  font-family: "Galano Grotesque";
  position: absolute;
  width: 25px;
  height: 25px;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Spinner = styled.div`
  width: 16px;
  height: 16px;
  svg {
    width: 16px;
    height: 16px;
    transform-origin: 50% 50%;
    animation: ${rotate} 2s linear infinite;
    fill: white;
  }
`

const Loader = () => {
  return (
    <Spinner>
      <svg viewBox="0 0 24 24">
        <path d="M0 11c.511-6.158 5.685-11 12-11s11.489 4.842 12 11h-2.009c-.506-5.046-4.793-9-9.991-9s-9.485 3.954-9.991 9h-2.009zm21.991 2c-.506 5.046-4.793 9-9.991 9s-9.485-3.954-9.991-9h-2.009c.511 6.158 5.685 11 12 11s11.489-4.842 12-11h-2.009z" />
      </svg>
    </Spinner>
  )
}

const Button = ({
  loading = false,
  primary = true,
  label = "",
  center = false,
  ...props
}: Props) => {
  return (
    <Container center={center}>
      <ButtonWrapper primary={primary} {...props}>
        <Label> {label} </Label>
        <Icon className="icon">{loading ? <Loader /> : <>&#8594;</>}</Icon>
      </ButtonWrapper>
    </Container>
  )
}

export default Button
