/**
 * @flow
 */

import React, { useMemo } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

type Props = {
  src: string,
  alt: string,
}

const Container = styled.div`
  /* overflow: hidden; */
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const Image = ({ src, alt, ...props }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { internal: { mediaType: { regex: "images/" } } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const match = useMemo(
    () => data.allFile.edges.find(({ node }) => src === node.relativePath),
    [data, src]
  )

  return (
    <Container>
      <Img fluid={match.node.childImageSharp.fluid} alt={alt} {...props} />
    </Container>
  )
}

Image.defaultProps = {
  src: "placeholder.jpg",
  alt: "Sound Idea Sessions",
}

export default Image
