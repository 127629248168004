/**
 * @flow
 */

import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Field, Form as FormikForm, Formik } from "formik"
import { useDispatch } from "react-redux"
import { navigate } from "gatsby"
import * as Yup from "yup"
// import { isMobile } from "react-device-detect"

import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import { Storage } from "aws-amplify"

import { notify } from "../../../state"
import media from "../../../styles/media"
import { Button, TextField, UploadField } from "../../elements"
import {
  validatePhoneNumber,
  toInternationalPhoneNumber,
} from "../../../utils/phoneNumber"

const validationSchema = Yup.object().shape({
  givenName: Yup.string().required("Required"),
  familyName: Yup.string().required("Required"),
  phoneNumber: Yup.string()
    .required("Required")
    .test("phone-number", "Invalid phone number", value =>
      validatePhoneNumber(value)
    ),
  city: Yup.string().required("Required"),
  deliveryAddress: Yup.string().required("Required"),
  biography: Yup.string().required("Required"),
  motivation: Yup.string().required("Required"),
})

const Container = styled.div`
  .inline-form {
    display: flex;
    flex-direction: column;
  }
  ${media.tablet`
    .inline-form {
      flex-direction: row;
      justify-content: space-between;
    }
  `}
`

const Form = ({ data }) => {
  const dispatch = useDispatch()

  const [profilePic, setProfilePic] = useState("")
  const [picUploading, setPicUploading] = useState(false)
  const [picProgress, setPicProgress] = useState(0)
  const [saveProfile, result] = useMutation(SAVE_PROFILE_MUTATION)

  useEffect(() => {
    fetchImg()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (result.error) {
      dispatch(
        notify({
          message: result.error.message,
          variant: "error",
        })
      )
    }
  }, [dispatch, result.error])

  const fetchImg = async () => {
    setPicUploading(true)
    Storage.configure({ level: "protected" })
    try {
      const result = await Storage.get(`${data.id}.jpg`)
      setProfilePic(result)
    } catch (err) {
      // console.log("Fetch img err: ", err)
    } finally {
      setPicUploading(false)
    }
  }

  const handleFileUpload = file => {
    setPicUploading(true)
    Storage.put(`${data.id}.jpg`, file, {
      level: "protected",
      progressCallback(progress) {
        var percentage = ((progress.loaded / progress.total) * 100).toFixed(0)
        setPicProgress(percentage)
      },
    })
      .then(result => {
        dispatch(
          notify({
            message: "Profile picture has been updated.",
            variant: "success",
          })
        )
        fetchImg()
        // console.log(result)
      })
      .catch(err => {
        /*console.log(err)*/
      })
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const {
      givenName,
      familyName,
      phoneNumber,
      city,
      biography,
      motivation,
      deliveryAddress,
    } = values
    const phone_number = toInternationalPhoneNumber(phoneNumber)

    try {
      await saveProfile({
        variables: {
          givenName,
          familyName,
          phoneNumber: phone_number,
          city,
          biography,
          motivation: motivation,
          deliveryAddress,
        },
      })
      dispatch(
        notify({
          message: "Profile saved successfully.",
          variant: "success",
        })
      )
      setSubmitting(false)
      window.setTimeout(() => {
        navigate("/review", { state: { status: data.status } })
      }, 500)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Container>
      <Formik
        initialValues={data}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        isTouched
      >
        {({ isSubmitting, dirty }) => (
          <FormikForm noValidate>
            <UploadField
              uploading={picUploading}
              handleOnChange={handleFileUpload}
              img={profilePic}
              progress={picProgress}
            />
            <div className="inline-form">
              <Field
                autoComplete="given-name"
                autoFocus
                component={TextField}
                id="givenName"
                placeholder="First Name"
                name="givenName"
                required
              />
              <Field
                autoComplete="family-name"
                component={TextField}
                id="familyName"
                placeholder="Surname"
                name="familyName"
                required
              />
            </div>
            <div className="inline-form">
              <Field
                autoComplete="tel"
                component={TextField}
                id="phoneNumber"
                placeholder="Mobile #"
                name="phoneNumber"
              />
              <Field
                autoComplete="city"
                component={TextField}
                id="city"
                placeholder="City"
                name="city"
              />
            </div>
            <Field
              autoComplete=""
              component={TextField}
              id="deliveryAddress"
              placeholder="Delivery address"
              name="deliveryAddress"
              help="please make sure to include your full address and postal code"
            />
            <Field
              autoComplete=""
              component={TextField}
              id="biography"
              placeholder="Short bio"
              name="biography"
              help="tell us a little bit about yourself"
            />
            <Field
              autoComplete=""
              component={TextField}
              id="motivation"
              placeholder="Short motivation"
              name="motivation"
              help="tell us why you should win a bursary"
            />
            <Button
              type="submit"
              label="Complete Profile"
              className="mouse-link"
              loading={isSubmitting}
              disabled={isSubmitting || !dirty}
              style={{ marginTop: "2rem" }}
            />
          </FormikForm>
        )}
      </Formik>
    </Container>
  )
}

Form.propTypes = {
  data: PropTypes.object,
}

Form.defaultProps = {
  data: {},
}

export default Form

const SAVE_PROFILE_MUTATION = gql`
  mutation SaveProfile(
    $givenName: String!
    $familyName: String!
    $phoneNumber: String!
    $city: String!
    $biography: String!
    $motivation: String!
    $deliveryAddress: String!
  ) {
    saveProfile(
      input: {
        givenName: $givenName
        familyName: $familyName
        phoneNumber: $phoneNumber
        city: $city
        biography: $biography
        motivation: $motivation
        deliveryAddress: $deliveryAddress
      }
    ) {
      __typename
      ... on SaveProfileSuccess {
        profile {
          id
          givenName
          familyName
          phoneNumber
          city
          deliveryAddress
          biography
          motivation
          status
        }
      }
      ... on Error {
        message
      }
    }
  }
`
