/**
 * @flow
 */
import React, { useRef, useState } from "react"
import styled, { keyframes } from "styled-components"
import { isMobile } from "react-device-detect"

import Label from "../label"
// import Spinner from "../Spinner"

type Props = {
  handleOnChange: Function,
  uploading: boolean,
  img: string,
  progress: number,
}

const Container = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const Field = styled.div`
  display: block;
  text-align: center;
  /* justify-content: space-between; */
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
  align-items: center;
  border: 2px dashed rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  transition: 300ms ease-out;
  p {
    font-family: "AvenirLTStdBlack";
    font-size: 1.1rem;
  }
  input {
    display: none;
  }
  &.active {
    border: 2px dashed rgba(0, 255, 0, 0.8);
  }
`

const Help = styled.p`
  font-size: 0.8rem;
  font-family: "AvenirLTStdMedium";
  padding-bottom: 1em;
`

const Error = styled.p`
  font-size: 0.8rem;
  font-family: "AvenirLTStdMedium";
  color: var(--carnation);
`

const Icon = styled.svg`
  width: 48px;
  height: 48px;
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: rgba(255, 255, 255, 0.8);
  &.success {
    fill: rgba(0, 255, 0, 0.8);
  }
`

const Preview = styled.div`
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
  }
`

const Loader = styled.div`
  width: 100px;
  height: 100px;
  line-height: 100px;
  background: rgba(196, 18, 48, 0.99);
  border-radius: 50px;
  color: black;
`

const UploadField = ({ handleOnChange, uploading, img, progress }: Props) => {
  const field = useRef(null)
  const [sizeError, setSizeError] = useState(null)
  const [fileTypeError, setFileTypeError] = useState(null)
  const [fileSet, setFileSet] = useState("")

  const renderIcon = () => {
    if (uploading) {
      return (
        <Loader>
          <p>{progress}%</p>
        </Loader>
      )
      // return <Spinner />
    } else {
      if (img != "") {
        return (
          <Preview id="circle">
            <img src={img} alt="" />
          </Preview>
        )
      } else {
        return (
          <Icon id="circle" width="24" height="24" viewBox="0 0 24 24">
            <path d="M11.492 10.172l-2.5 3.064-.737-.677 3.737-4.559 3.753 4.585-.753.665-2.5-3.076v7.826h-1v-7.828zm7.008 9.828h-13c-2.481 0-4.5-2.018-4.5-4.5 0-2.178 1.555-4.038 3.698-4.424l.779-.14.043-.789c.185-3.448 3.031-6.147 6.48-6.147 3.449 0 6.295 2.699 6.478 6.147l.044.789.78.14c2.142.386 3.698 2.246 3.698 4.424 0 2.482-2.019 4.5-4.5 4.5m.978-9.908c-.212-3.951-3.472-7.092-7.478-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.522-5.408" />
          </Icon>
        )
      }
    }
  }

  const renderLabel = () => {
    if (isMobile) {
      return <p> Upload your Profile pic </p>
    } else {
      return <p> Drag and drop profile pic here </p>
    }
  }

  const allowFileDrop = e => {
    e.preventDefault()
  }

  const handleOnDrop = e => {
    // console.log("handling on file drop")
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    // console.log("file: ")
    // console.log(file)
    if (file.size > 2000000) {
      // console.log("file size too big")
      setSizeError(true)
    } else if (file.type != "image/png" && file.type != "image/jpeg") {
      // console.log("file type incorrect")
      setFileTypeError(true)
    } else {
      setFileSet(file.name)
      handleOnChange(file)
      if (sizeError) {
        setSizeError(false)
      }
      if (fileTypeError) {
        setFileTypeError(false)
      }
    }
  }

  const triggerFileSelect = () => {
    const trigger = document.getElementById("fileToUpload")
    if (trigger) {
      trigger.click()
    }
  }

  const handleFileSelected = e => {
    // console.log("input on change triggered")
    const file = e.target.files[0]
    setFileSet(file.name)
    handleOnChange(file)
  }

  const renderErrors = () => {
    if (sizeError && fileTypeError) {
      return (
        <Error>
          The max file size is 2MB <br /> Please upload a .png or .jpeg
        </Error>
      )
    } else if (sizeError) {
      return <Error>The max file size is 2MB</Error>
    } else if (fileTypeError) {
      return <Error>Please upload a .png or .jpeg</Error>
    } else {
      return null
    }
  }

  return (
    <Container onDrop={handleOnDrop} onDragOver={allowFileDrop}>
      <Field ref={field}>
        {renderIcon()}
        {fileSet ? <p>{fileSet.substring(0, 10)}...</p> : renderLabel()}
        <Label
          value="upload"
          innerRotate={0}
          outerRotate={0}
          fontSize={0.8}
          onClick={triggerFileSelect}
        />
        <input
          type="file"
          name="fileToUpload"
          id="fileToUpload"
          onChange={handleFileSelected}
        />
      </Field>
      <Help> Please upload a square photo of your face </Help>
      {renderErrors()}
    </Container>
  )
}

export default UploadField
