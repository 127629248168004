/**
 * @flow
 */

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import media from "../../styles/media"

type Props = {
  value: string,
  fontSize?: number,
  fontScaled?: boolean,
  innerRotate?: number,
  outerRotate?: number,
  pLeft?: number,
  pRight?: number,
  mLeft?: number,
  mRight?: number,
  invert?: boolean,
  url?: string,
  to?: string,
}

const Container = styled.div`
  margin-left: ${props => `${props.mLeft}rem`};
  margin-right: ${props => `${props.mRight}rem`};
  padding-left: ${props => `${props.pLeft}rem`};
  padding-right: ${props => `${props.pRight}rem`};
  padding-top: 0.45rem;
  padding-bottom: 0.25rem;
  display: inline-block;
  position: relative;
  color: ${props =>
    props.invert ? `rgba(0, 0, 0, 0.8)` : `rgba(255, 255, 255, 0.8)`};
  font-family: "AvenirLTStdBlack";
  text-transform: uppercase;
  transform: ${props => `rotate(${props.outerRotate}deg)`};
  font-size: ${props =>
    props.fontScaled ? `${props.scaledFontSize}rem` : `${props.fontSize}rem`};
  a,
  span {
    display: inline-block;
  }
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props =>
      props.invert ? `rgba(255, 255, 255, 0.8)` : `rgba(0, 0, 0, 0.8)`};
    transform: ${props => `rotate(${props.innerRotate}deg)`};
  }
  ${media.tablet`
    font-size: ${props => `${props.fontSize}rem`}
  `}
`

const Label = ({
  value,
  fontSize,
  fontScaled,
  innerRotate,
  outerRotate,
  pLeft,
  pRight,
  mLeft,
  mRight,
  invert,
  url,
  to,
  ...props
}: Props) => {
  let scaledFontSize = fontSize
  if (fontScaled && fontSize) {
    scaledFontSize = 0.8 * fontSize
  }

  const renderLabel = () => {
    if (url && !to) {
      return (
        <a href={url} target="_blank" rel="noreferrer noopener">
          {value}
        </a>
      )
    } else if (!url && to) {
      return <Link to={to}> {value} </Link>
    } else {
      return <span>{value}</span>
    }
  }

  return (
    <Container
      fontSize={fontSize}
      scaledFontSize={scaledFontSize}
      fontScaled={fontScaled}
      innerRotate={innerRotate}
      outerRotate={outerRotate}
      pLeft={pLeft}
      pRight={pRight}
      mLeft={mLeft}
      mRight={mRight}
      invert={invert}
      {...props}
    >
      {renderLabel()}
    </Container>
  )
}

Label.defaultProps = {
  value: "Label",
  fontSize: 1,
  fontScaled: false,
  innerRotate: 0,
  outerRotate: 0,
  pLeft: 1,
  pRight: 1,
  mLeft: 0,
  mRight: 0,
  invert: false,
}

export default Label
