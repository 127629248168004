/**
 * @flow
 */
import React from "react"
import styled from "styled-components"
import { Link } from "react-scroll"
import { Link as HomeLink } from "gatsby"

import Image from "../elements/image"
import Label from "../elements/label"
import Subscribe from "./Subscribe"

import media from "../../styles/media"

type Props = {
  items?: Array<Object>,
  back?: boolean,
}

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template: auto auto auto auto / 1fr;
  margin-bottom: 3rem;
  ${media.tablet`
    grid-template: auto / 3fr 1fr 1fr 1fr;
    margin-bottom: 15vh;
  `}
`

const PresentedLabel = styled(Label)`
  margin-bottom: 1rem;
`

const Newsletter = styled.div`
  margin-bottom: 2rem;
  a {
    display: inline-block;
  }
  .gatsby-image-wrapper {
    width: 200px;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
  h4 {
    text-transform: uppercase;
    font-size: 1.85rem;
    margin-bottom: 1rem;
    color: rgba(0, 0, 0, 0.8);
  }
  form {
    max-width: 300px;
  }
  ${media.tablet`
    margin-bottom: 0;
  `}
`

const LinkMenu = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  h4 {
    font-family: "AvenirLTStdBlack";
    margin-bottom: 0.5rem;
    color: rgba(0, 0, 0, 0.8);
  }
  ul {
    li {
      list-style: none;
      margin-bottom: 0rem;
      a {
        font-family: "AvenirLTStdMedium";
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.8);
        display: inline-block;
        padding: 0.5rem 0;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
  ${media.tablet`
    text-align: left;
    padding-top: 10vh;
    margin-bottom: 0;
    ul {
      li {
        a {
          cursor: pointer;
        }
      }
    }
  `}
`

const TopNav = ({ items, back }: Props) => {
  const renderMenuItems = () => {
    if (items) {
      return items.map((item, i) => {
        return (
          <li key={i}>
            <Link
              className="mouse-link"
              to={item.name}
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              {item.name}
            </Link>
          </li>
        )
      })
    }
  }

  return (
    <Container>
      <Newsletter>
        <PresentedLabel
          fontSize={0.8}
          outerRotate={-8}
          innerRotate={-2}
          value="Presented by"
        />
        <br />
        <a
          href="http://brainfarm.co.za/"
          target="_blank"
          rel="noreferrer noopener"
          className="mouse-link"
        >
          <Image
            src="Footer/brainfarm.png"
            alt="Sound Idea Sessions | Brainfarm"
            className="presenter"
          />
        </a>
        <h4>
          A harvest of the <br />
          world&apos;s brightest <br />
          minds.
        </h4>
        <Subscribe />
      </Newsletter>
      <LinkMenu>
        <h4>Nav</h4>
        <ul>
          {back && (
            <li>
              <HomeLink className="mouse-link" to="/">
                Home
              </HomeLink>
            </li>
          )}
          {items && renderMenuItems()}
        </ul>
      </LinkMenu>
      <LinkMenu>
        <h4>Follow</h4>
        <ul>
          <li>
            <a
              href="https://www.facebook.com/soundideasessions/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/soundideasessions/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              Instagram
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/SISessions"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              Twitter
            </a>
          </li>
        </ul>
      </LinkMenu>
      <LinkMenu>
        <h4>Other</h4>
        <ul>
          <li>
            <a
              href="mailto:contact@soundideasessions.com?subject=Sound Idea Sessions Support"
              target="_top"
              className="mouse-link"
            >
              Send us an email
            </a>
          </li>
          <li>
            <a
              href="mailto:sponsor@soundideasessions.com?subject=Sound Idea Sessions Sponsor"
              target="_top"
              className="mouse-link"
            >
              Become a sponsor
            </a>
          </li>
          <li>
            <a
              href="mailto:media@soundideasessions.com?subject=Sound Idea Sessions Media Inquiry"
              target="_top"
              className="mouse-link"
            >
              Media Inquiries
            </a>
          </li>
        </ul>
      </LinkMenu>
    </Container>
  )
}

export default TopNav
