import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Image from "../elements/image"
import Label from "../elements/label"

import media from "../../styles/media"

// import logo_sis from "../../images/logos/logo-sis.png"
// import logo_teamgeek from "../../images/logos/logo-teamgeek.png"

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${media.tablet`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`

const InnerNav = styled.div`
  display: flex;
  margin-bottom: 3rem;
  a {
    &.sis-logo {
      display: none;
    }
  }
  ul {
    display: flex;
    width: 100%;
    justify-content: space-around;
    li {
      list-style: none;
      margin-left: 0 auto;
      a {
        font-family: "AvenirLTStdBlack";
        font-size: 0.8rem;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
  ${media.tablet`
    align-items: center;
    margin-bottom: 0;
    a {
      &.sis-logo {
        display: inline-block;
        width: 135px;
        height: 135px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    ul {
      width: auto;
      li {
        margin-left: 2.5rem;
      }
    }
  `}
`

const CreativePartner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    width: 110px;
    height: 110px;
    .gatsby-image-wrapper {
      width: 110px;
      height: 110px;
      img {
        object-fit: contain !important;
      }
    }
  }
  ${media.tablet`
    justify-content: flex-end;
    a {
      width: 135px;
      height: 135px;
      .gatsby-image-wrapper {
        width: 135px;
        height: 135px;
      }
    }
  `}
`

const BottomNav = () => {
  return (
    <Container>
      <InnerNav>
        <Link to="/" className="sis-logo mouse-link">
          {/* <img src={logo_sis} alt="Sound Idea Sesssions" /> */}
          <Image src="Footer/sis.png" alt="Sound Idea Sesssions" />
        </Link>
        <ul>
          <li>
            <Link to="/terms" className="mouse-link">
              Terms + Conditions
            </Link>
          </li>
          <li>
            <Link to="/faq" className="mouse-link">
              FAQs
            </Link>
          </li>
        </ul>
      </InnerNav>
      <CreativePartner>
        <Label
          fontSize={0.8}
          outerRotate={0}
          innerRotate={-2}
          value="Creative Partner"
        />
        <a
          href="https://teamgeek.io/"
          target="_blank"
          rel="noreferrer noopener"
          className="logo-wrapper mouse-link"
        >
          {/* <img src={logo_teamgeek} alt="Sound Idea Sesssions | Teamgeek" /> */}
          <Image
            src="Footer/teamgeek.png"
            alt="Sound Idea Sesssions | Teamgeek"
          />
        </a>
      </CreativePartner>
    </Container>
  )
}

export default BottomNav
