/**
 * @flow
 */

import React from "react"
import styled from "styled-components"

type Props = {
  field: Object,
  form: Object,
  type?: string,
  help?: string,
  multiline?: boolean,
  normalize?: Function,
}

const Container = styled.div`
  margin-bottom: 1.5rem;
  p {
    margin-bottom: 0.25rem !important;
    input,
    textarea {
      width: 100%;
      font-family: "AvenirLTStdBlack";
      font-size: 1.25rem;
      background-color: transparent;
      border: none;
      color: rgba(255, 255, 255, 1);
      border-bottom: 2px solid rgba(255, 255, 255, 1);
      border-radius: 0px;
      ::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 1);
      }
      ::-moz-placeholder {
        color: rgba(255, 255, 255, 1);
      }
      :-ms-input-placeholder {
        color: rgba(255, 255, 255, 1);
      }
      :-moz-placeholder {
        color: rgba(255, 255, 255, 1);
      }
      &:focus {
        outline: none;
        box-shadow: 2px 2px 10px -8px rgba(0, 0, 0, 0.5);
      }
    }
  }
  span {
    font-size: 0.9rem;
    font-family: "AvenirLTStdMedium";
    min-height: 15px;
    line-height: 15px;
    display: inline-block;
  }
`

const TextField = ({
  field,
  form,
  type = "text",
  help = "",
  multiline = false,
  normalize = v => v,
  ...props
}: Props) => {
  const error = form.errors[field.name] && form.touched[field.name]

  const handleBlur = () => {
    form.setFieldTouched(field.name, true)
  }

  const handleChange = event => {
    const value = normalize(event.target.value)
    form.setFieldValue(field.name, value)
  }

  return (
    <Container>
      <p>
        {multiline ? (
          <textarea
            onBlur={handleBlur}
            onChange={handleChange}
            value={field.value}
            {...props}
          />
        ) : (
          <input
            type={type}
            onBlur={handleBlur}
            onChange={handleChange}
            value={field.value}
            {...props}
          />
        )}
      </p>
      <span>
        {help && !error
          ? help
          : help && error
          ? form.errors[field.name]
          : !help && error
          ? form.errors[field.name]
          : ""}
      </span>
    </Container>
  )
}

export default TextField
