/**
 * @flow
 */
import React from "react"
import styled from "styled-components"

import media from "../../styles/media"
import Image from "../elements/image"

import TopNav from "./TopNav"
import BottomNav from "./BottomNav"

type Props = {
  items?: Array<Object>,
  back?: boolean,
}

const Container = styled.footer`
  max-width: 100vw;
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  padding: var(--gutter-l) var(--gutter-s);
  ${media.tablet`
    padding: var(--gutter-l) var(--gutter-l);
  `}
`

const Texture = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
`

const Footer = ({ items, back }: Props) => {
  return (
    <Container>
      <Texture>
        <Image src="textures/texture-footer.png" alt="Sound Idea Sessions" />
      </Texture>
      <TopNav items={items} back={back} />
      <BottomNav />
    </Container>
  )
}

export default Footer
