/**
 * @flow
 */

import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { navigate } from "gatsby"

import Label from "../../elements/label"

import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"

import Form from "./form"
import media from "../../../styles/media"
import PageHeader from "../../elements/PageHeader"

import { notify } from "../../../state"
import info from "../../../images/Auth/info-icon.svg"

import Spinner from "../../elements/Spinner"

type Props = {
  location: Object,
}

const Container = styled.div`
  &.red {
    background-color: rgba(196, 18, 48, 0.99);
  }
  &.black {
    background-color: rgba(63, 63, 63, 0.99);
  }
  &.p-left {
    padding-left: var(--gutter-s);
  }
  &.p-left-l {
    padding-left: var(--gutter-l);
  }
  &.p-right {
    padding-right: var(--gutter-s);
  }
  &.p-top,
  &.p-bottom {
    padding-bottom: 10vh;
  }
  ${media.tablet`
    &.p-left {
      padding-left: 10vw;
    }
    &.p-left-l {
      padding-left: 10vw;
    }
    &.p-right {
      padding-right: 5vw;
    }
    &.p-top,
    &.p-bottom {
      padding-bottom: 15vh;
    }
  `}
  ${media.laptop`
    &.p-left {
      padding-left: 25vw;
    }
    &.p-left-l {
      padding-left: 25vw;
    }
    &.p-right {
      padding-right: 20vw;
    }
  `}
`

const Intro = styled.div`
  margin-bottom: 2rem;
  position: relative;
  padding-top: 60px;
  h1,
  p {
    color: rgba(255, 255, 255, 0.8);
  }
  h1 {
    font-family: "AvenirLTStdBlack";
    font-size: 3rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-50%) rotate(-2deg);
  }
  p {
    display: inline;
    font-family: "butlermedium";
    line-height: 1.75rem;
  }
  ${media.laptop`
    padding-top: 80px;
    h1 {
      font-size: 8rem;
    }
  `}
`

const Protection = styled.div`
  display: flex;
  align-items: center;
  padding-top: 2rem;
  img {
    height: 20px;
    width: auto;
    margin-right: 20px;
  }
  p {
    font-family: "AvenirLTStdMedium";
    font-size: 0.8rem;
    line-height: 1rem;
  }
`

const ProfilePage = ({ location }: Props) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const hasUser = useSelector(state => state.auth.hasUser)

  useEffect(() => {
    if (hasUser && !user) {
      dispatch(
        notify({
          message: "You need to be signed in first.",
          variant: "info",
        })
      )
      window.setTimeout(() => {
        navigate("/sign-in")
      }, 500)
    }
  }, [dispatch, user, hasUser])

  let isWelcome = false
  if (location) {
    if (location.state) {
      isWelcome = location.state.isWelcome
    }
  }

  const { data } = useQuery(PROFILE_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  const renderForm = () => {
    if (data) {
      if (data.me) {
        return <Form data={data.me.profile} />
      } else {
        return <Spinner />
      }
    }
  }

  return (
    <>
      <Container className="red p-left-l p-bottom">
        <PageHeader />
      </Container>
      <Container className="black p-left p-right p-bottom">
        <Intro>
          <h1>Profile</h1>
          {!isWelcome ? (
            <>
              <p>
                Thanks for verifying your email address. Complete your profile
                to start your Growth Hacker Marketing Journey OR
              </p>
              &nbsp; &nbsp;
              <Label
                to="/profiles"
                fontSize={0.8}
                outerRotate={-1}
                innerRotate={0}
                value="Vote Here"
                className="mouse-link"
              />
            </>
          ) : (
            <p></p>
          )}
        </Intro>
        {renderForm()}
        <Protection>
          <img src={info} alt="Sound Idea Sessions | Profile" />
          <p>Your information is protected</p>
        </Protection>
      </Container>
    </>
  )
}

export default ProfilePage

const PROFILE_QUERY = gql`
  query {
    me {
      id
      email
      profile {
        id
        givenName
        familyName
        phoneNumber
        city
        deliveryAddress
        biography
        motivation
        status
      }
    }
  }
`
