/**
 * @flow
 */

import React from "react"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import ProfilePage from "../components/Auth/ProfilePage"

type Props = {
  location: Object,
}

const Profile = ({ location }: Props) => {
  const menuItems = [
    { name: "Leaderboard", path: "/leaderboard" },
    // { name: "Profiles", path: "/profiles" },
  ]
  return (
    <>
      <SEO title="Profile" />
      <Navbar isGHP back items={menuItems} topOffset={0} bottomOffset={125} />
      <ProfilePage location={location} />
      <Footer back />
    </>
  )
}

export default Profile
